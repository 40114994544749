import React, { useEffect, useState } from 'react';
import CenterService from '../../../../services/center.service';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CommonService from '../../../../services/common.service';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import { EmailValidator, GstValidator, MobileNumberValidator } from '../../../../helpers/Validator.helper';


interface ICenterBasicDetail {
    reload: any
}
export default function CenterBasicDetail(props: ICenterBasicDetail) {

    const [centerDetails, setCenterDetails] = useState<any>({});
    const [area, setArea] = useState<any>();
    const [areaSelectOptions, setAreaSelectOptions] = useState<any>({});
    const [pincode, setPincode] = useState<any>();

    const [isDisabled, setIsDisabled] = useState<boolean>(true);


    const getPincodeDetails = async (pincode: any) => {
        if (!pincode) {
            // toast.error("Pincode is Mandatory");
            return false;
        }
        await CommonService.getPincodeInfo(pincode).then(res => {
            if (res.status === 200) {
                setPincode({
                    districtId: res.data._id,
                    districtName: res.data.district,
                    state: res.data.state,
                    pincode: res.data.pincode
                })
                const areaData: any = res.data.area.map((data: any) => {
                    return {
                        label: data.officename,
                        value: data._id
                    }
                })
                setAreaSelectOptions(areaData);
            }
        })
    }



    const getCenterDetails = async () => {
        await CenterService.getCenterDetails().then(res => {
            if (res.status == 200) {
                const center = res.data.center;
                const pincode = res.data.pincode;
                getPincodeDetails(pincode.pincode)
                setCenterDetails({
                    spocName: center.spocName,
                    centerContact: center.centerContact,
                    centerEmail: center.centerEmail,
                    name: center.name,
                    gstNumber: center.gstNumber,
                    address: center.address,
                    taluka: center.taluka,
                    venueItPersonName: center.venueItPersonName,
                    nameOfDivisionHq: center.nameOfDivisionHq,
                    venueITPersonContactNo: center.venueITPersonContactNo,
                    venueItEmailId: center.venueItEmailId,
                });
                setArea(center.area);
            }
        });
    }



    const handleCenterDetailsChange = (e: any) => {
        setCenterDetails({ ...centerDetails, [e.target.name]: e.target.value })
    }

    // const onSave = async () => {
    //     if (!MobileNumberValidator(centerDetails.venueITPersonContactNo)) {
    //         toast.error("Enter Valid Venue IT Person Contact No.")
    //         return false
    //     }
    //     if (!MobileNumberValidator(centerDetails.centerContact)) {
    //         toast.error("Enter Valid  Contact No.")
    //         return false
    //     }
    //     if (!GstValidator(centerDetails.gstNumber)) {
    //         toast.error("Enter Valid GST No.")
    //         return false
    //     }
    //     setIsDisabled(true);
    //     const payload = {
    //         center: {
    //             centerContact: centerDetails.centerContact,
    //             centerEmail: centerDetails.centerEmail,
    //             name: centerDetails.name,
    //             gstNumber: centerDetails.gstNumber,
    //             spocName: centerDetails.spocName,
    //             address: centerDetails.address,
    //             taluka: centerDetails.taluka,
    //             venueItPersonName: centerDetails.venueItPersonName,
    //             nameOfDivisionHq: centerDetails.nameOfDivisionHq,
    //             venueITPersonContactNo: centerDetails.venueITPersonContactNo,
    //             venueItEmailId: centerDetails.venueItEmailId,
    //         },
    //         pincode: {
    //             districtId: pincode.districtId,
    //             pincode: pincode.pincode,
    //             district: pincode.districtName,
    //             stateName: pincode.state,
    //         }
    //     }

    //     await CenterService.updateCenterBasicDetails(payload).then(res => {
    //         if (res.status === 200) {
    //             toast.success("Data updated successfully.");
    //             setIsDisabled(true);
    //             getCenterDetails()
    //         }
    //     }).catch(e => {
    //         toast.error(e.response.data.message)
    //     })
    //     setIsDisabled(false)
    // }

    const onSave = async () => {
        const { venueITPersonContactNo, centerContact, gstNumber, venueItEmailId, centerEmail, ...otherCenterDetails } = centerDetails;
        const { districtId, pincode: pincodeNumber, districtName, state } = pincode;

        const validators = [
            { valid: MobileNumberValidator(venueITPersonContactNo), message: "Enter Valid Venue IT Person Contact No." },
            { valid: MobileNumberValidator(centerContact), message: "Enter Valid Contact No." },
            { valid: GstValidator(gstNumber), message: "Enter Valid GST No." },
            { valid: EmailValidator(centerEmail), message: "Enter Valid Center Email." },
            { valid: EmailValidator(venueItEmailId), message: "Enter Valid Venue IT Email." }
        ];

        for (const { valid, message } of validators) {
            if (!valid) {
                toast.error(message);
                return false;
            }
        }

        setIsDisabled(true);

        const payload = {
            center: { venueITPersonContactNo, centerContact, gstNumber, ...otherCenterDetails },
            pincode: {
                districtId,
                pincode: pincodeNumber,
                district: districtName,
                stateName: state,
            },
        };

        try {
            const res = await CenterService.updateCenterBasicDetails(payload);

            if (res.status === 200) {
                toast.success("Data updated successfully.");
                getCenterDetails();
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "An error occurred";
            toast.error(errorMessage);
        } finally {
            setIsDisabled(false);
        }
    };




    console.log(centerDetails, "centerDetails")

    useEffect(() => {
        getCenterDetails();
    }, [])

    return (
        <>
            <Card className="xcn-card mt-3">
                <div className='d-flex justify-content-between align-items-center'>
                    <h5 className='fw-bold text-secondary'>Basic Details</h5>
                    {
                        isDisabled ? <Button variant='primary' size="sm" onClick={() => setIsDisabled(false)} ><FontAwesomeIcon icon={faEdit} /> Edit</Button>
                            :
                            <Button variant="outline-secondary" size="sm" onClick={onSave}><FontAwesomeIcon icon={faSave} /> Save</Button>
                    }
                </div>
                <Row className='mt-3'>
                    <Col md={6}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="fw-bold xcn-text-12 fw-bold text-muted">SPOC Name</Form.Label>
                            <Form.Control type="text" placeholder="SPOC Name" name="spocName" value={centerDetails.spocName} onChange={handleCenterDetailsChange} disabled={isDisabled} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="fw-bold xcn-text-12 fw-bold text-muted">Email</Form.Label>
                            <Form.Control type="email" placeholder="email@yourdomain.com" name="centerEmail" value={centerDetails.centerEmail} onChange={handleCenterDetailsChange} disabled={isDisabled} required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col md={6}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="fw-bold xcn-text-12 fw-bold text-muted">Contact</Form.Label>
                            <Form.Control type="text" placeholder="Contact" name="centerContact" value={centerDetails.centerContact} onChange={handleCenterDetailsChange} disabled={isDisabled} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="fw-bold xcn-text-12 fw-bold text-muted">Registered Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" name="name" value={centerDetails.name} onChange={handleCenterDetailsChange} disabled={isDisabled} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col md={6}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="fw-bold xcn-text-12 fw-bold text-muted">GST Number</Form.Label>
                            <Form.Control type="text" placeholder="GST Number" name="gstNumber" value={centerDetails.gstNumber} onChange={handleCenterDetailsChange} disabled={isDisabled} />
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="fw-bold xcn-text-12 fw-bold text-muted">Taluka</Form.Label>
                            <Form.Control type="text" placeholder="Taluka" name="taluka" value={centerDetails.taluka} onChange={handleCenterDetailsChange} disabled={isDisabled} />
                        </Form.Group>
                    </Col>
                </Row>


                {/* addedd */}

                <Row className='mt-2'>
                    <Col md={6}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="fw-bold xcn-text-12 fw-bold text-muted">Name of Division (HQ)</Form.Label>
                            <Form.Control type="text" placeholder="Name of Division" name="nameOfDivisionHq" value={centerDetails.nameOfDivisionHq} onChange={handleCenterDetailsChange} disabled={isDisabled} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="fw-bold xcn-text-12 fw-bold text-muted">Venue IT Person Name</Form.Label>
                            <Form.Control type="text" placeholder="Venue IT Person Name" name="venueItPersonName" value={centerDetails.venueItPersonName} onChange={handleCenterDetailsChange} disabled={isDisabled} />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mt-2'>
                    <Col md={6}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="fw-bold xcn-text-12 fw-bold text-muted">Venue IT Person Contact No.</Form.Label>
                            <Form.Control type="text" placeholder="Venue IT Person Contact No." name="venueITPersonContactNo" value={centerDetails.venueITPersonContactNo} onChange={handleCenterDetailsChange} disabled={isDisabled} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="fw-bold xcn-text-12 fw-bold text-muted">Venue IT Person Email ID</Form.Label>
                            <Form.Control type="email" placeholder="Venue IT Person Email ID" name="venueItEmailId" value={centerDetails.venueItEmailId} onChange={handleCenterDetailsChange} disabled={isDisabled} required />
                        </Form.Group>
                    </Col>
                </Row>

                {/* addedd end */}

                {/* <h5 className='fw-bold text-secondary mt-4'>Address</h5> */}
                <Row className='mt-2'>
                    <Col md={12}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="fw-bold xcn-text-12 fw-bold text-muted">Address</Form.Label>
                            <Form.Control type="text" placeholder="Name" name="address" value={centerDetails.address} onChange={handleCenterDetailsChange} disabled={isDisabled} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className='d-flex justify-content-start align-items-center'><div className='fw-bold text-secondary xcn-text-12'>Pincode</div> <Button variant="primary" onClick={() => getPincodeDetails(pincode)} className="ms-2" size="sm" disabled={isDisabled}>
                                Get Pincode Data
                            </Button></Form.Label>
                            <Form.Control type="text" placeholder="110057" defaultValue={pincode && pincode.pincode} onChange={(e) => setPincode(e.target.value)} disabled={isDisabled} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col md={6}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="fw-bold xcn-text-12 fw-bold text-muted">City</Form.Label>
                            <Form.Control type="text" placeholder="City" value={pincode?.districtName} disabled={true} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="fw-bold xcn-text-12 fw-bold text-muted">State</Form.Label>
                            <Form.Control type="text" placeholder="City" value={pincode?.state} disabled={true} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-2'>

                </Row>
            </Card>

        </>
    )
}   